import * as React from "react"

import Layout from "../../Layout/layout"
import Seo from "../../seo"

const skuDetail = () => {
  return (
    <Layout>
      <Seo title="SKU" />
      <div>SKU</div>
    </Layout>
  )
}

export default skuDetail
